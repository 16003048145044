// extracted by mini-css-extract-plugin
export var eventId = "_eventId-module--eventId--JLhak";
export var eventId__inner = "_eventId-module--eventId__inner--s4bQt";
export var eventId__wrapper = "_eventId-module--eventId__wrapper--1Ni+s";
export var eventId__set = "_eventId-module--eventId__set--uUmBi";
export var eventId__event_category = "_eventId-module--eventId__event_category--o-t8D";
export var eventId__thumbnail = "_eventId-module--eventId__thumbnail--QKn4b";
export var eventId__description = "_eventId-module--eventId__description--995rG";
export var eventId__theme__wrap = "_eventId-module--eventId__theme__wrap--8SOEz";
export var eventId__theme = "_eventId-module--eventId__theme--NJL8Q";
export var eventId__theme__title = "_eventId-module--eventId__theme__title--QYXY3";
export var eventId__theme__red = "_eventId-module--eventId__theme__red--oMaLQ";
export var eventId__theme__blue = "_eventId-module--eventId__theme__blue--oqaBZ";
export var eventId__roulette = "_eventId-module--eventId__roulette--Rc+Wf";
export var eventId__btn = "_eventId-module--eventId__btn--Vwbly";
export var eventId__share = "_eventId-module--eventId__share--z4aWP";
export var tab_list = "_eventId-module--tab_list--IRNrQ";
export var tab_list__not = "_eventId-module--tab_list__not--epuEF";
export var tab_list__pop_icon = "_eventId-module--tab_list__pop_icon--VKdGN";
export var tab_list__tab = "_eventId-module--tab_list__tab--gCAtc";
export var tab_list__tab__active = "_eventId-module--tab_list__tab__active--zjkHp";
export var menu_list = "_eventId-module--menu_list--XKPFH";
export var seasonId__menu_list__current = "_eventId-module--seasonId__menu_list__current--LaEPi";
export var tab_panel = "_eventId-module--tab_panel--ZPDxn";
export var tab_panel__not = "_eventId-module--tab_panel__not--O9sG3";
export var tab_panel__active = "_eventId-module--tab_panel__active--mhN9T";
export var tab_panel__about = "_eventId-module--tab_panel__about--Iq6Qt";
export var tab_panel__about__section = "_eventId-module--tab_panel__about__section--2HWAE";
export var eventId__judge_list = "_eventId-module--eventId__judge_list--ZOpZQ";
export var result__ul = "_eventId-module--result__ul--MChf5";
export var result__li = "_eventId-module--result__li--JBFhW";
export var result__box = "_eventId-module--result__box--AobOe";
export var result__winner = "_eventId-module--result__winner--QV36f";
export var result__artist = "_eventId-module--result__artist--8nx5Y";
export var result__artist__wrap = "_eventId-module--result__artist__wrap--aYvpU";
export var result__artist__wrap__name = "_eventId-module--result__artist__wrap__name--ZI9UZ";
export var result__name = "_eventId-module--result__name--m3VCi";
export var result__list = "_eventId-module--result__list--WV72K";
export var result__date = "_eventId-module--result__date--ymZDW";
export var result__match_title = "_eventId-module--result__match_title--fG5TH";
export var result__number_wrap = "_eventId-module--result__number_wrap--lN1+b";
export var result__number = "_eventId-module--result__number--tJLml";
export var result__vs = "_eventId-module--result__vs--SO8PZ";
export var result__btn = "_eventId-module--result__btn--HhMGC";
export var result__share = "_eventId-module--result__share--w-oB5";
export var artist_list = "_eventId-module--artist_list--KD-1g";