import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { dayjs } from '../../lib/dayjs';
import { Layout } from '../../components/layout';
import { BreadCrumb } from '../../components/breadcrumb';
import { BackGround } from '../../components/background';
import { Seo } from '../../components/seo';
import { isNewsTypeColor } from '../../lib/is-news-type-color';
import { FacebookShareButton, TwitterShareButton, LineShareButton } from 'react-share';
import * as Styles from '../../styles/pages/_eventId.module.scss';
import * as CommonStyles from '../../styles/global/_common.module.scss';
import {Link, useIntl} from "gatsby-plugin-react-intl"

import TwitterIcon from '/static/twitter_white_icon.svg';
import FacebookIcon from '/static/facebook_white_icon.svg';
import LineIcon from '/static/line_white_icon.svg';
import BlankIcon from '/static/blank_icon.svg';
import defaultUserIcon from '../../images/icon_user_default.jpg';
import { contentPathHelper } from '../../utils/contentPathHelper';

const EventSinglePage = ({ data, location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  const [tabIndex, setTabIndex] = React.useState(0);

  const microcmsEvent = data.microcmsEvent;
  const microcmsMatch = data.allMicrocmsMatch;

  const postDescription = data.microcmsEvent.description?.replace(/(<([^>]+)>)/gi, '').substr(0, 70);

  const matchComponent = (node) => (
    <li key={node.matchId} className={Styles.result__li}>
      <p className={Styles.result__name}>{node.event.title}</p>
      <div className={Styles.result__list}>
        <p className={Styles.result__date}>{dayjs(node.date).format('MM月DD日(ddd)')}</p>

        <p className={Styles.result__match_title}>{node.title}</p>
        <div className={Styles.result__box}>
          {/*青アーティストアイコン*/}
          <div className={Styles.result__artist__wrap}>
            <div className={classNames(node.winner[0] === '青' && Styles.result__winner)}>
              <div className={Styles.result__artist} data-type={node.blue.length > 1 ? 'double' : 'single'}>
                {node.blue.length > 0 && node.blue.map((b, i) => (
                  <img key={i} src={b.image_url || b.image.url + '?w=200&q=80&fm=webp'} alt={`${b.name}${t('match.icon')}`} />
                ))}
                {(!node.blue || node.blue && node.blue.length === 0) && (
                  <img src={defaultUserIcon + '?w=200&q=80&fm=webp'} alt="" />
                )}
              </div>
            </div>
            {node.blue && node.blue.map((b, i) => (
              <Link className={Styles.result__artist__wrap__name} key={i} to={`/artist/${contentPathHelper(b.id)}/`}>{b.name}</Link>
            ))}
          </div>

          <div>
            <div className={Styles.result__number_wrap}>
              <p className={Styles.result__vs}>VS</p>
            </div>
            <p className={Styles.result__btn}>
              <Link to={`/match/${contentPathHelper(node.matchId)}/`}>{t('news.matchDetail')}</Link>
            </p>
          </div>

          {/*赤アーティストアイコン*/}
          <div className={Styles.result__artist__wrap}>
            <div className={classNames(node.winner[0] === '赤' && Styles.result__winner)}>
              <div className={Styles.result__artist} data-type={node.red.length > 1 ? 'double' : 'single'}>
                {node.red.length > 0 && node.red.map((r, i) => (
                  <img key={i} src={r.image_url || r.image.url + '?w=200&q=80&fm=webp'} alt={`${r.name}${t('match.icon')}`} />
                ))}
                {(!node.red || node.red && node.red.length === 0) && (
                  <img src={defaultUserIcon + '?w=200&q=80&fm=webp'} alt="" />
                )}
              </div>
            </div>
            {node.red && node.red.map((r, i) => (
              <Link className={Styles.result__artist__wrap__name} key={i} to={`/artist/${contentPathHelper(r.id)}/`}>{r.name}</Link>
            ))}
          </div>

        </div>

        <div className={Styles.result__share}>
          <TwitterShareButton url={`${location.origin}/match/${node.matchId}`} title={node.title}>
            <img src={TwitterIcon} alt="Twitter share" />
          </TwitterShareButton>
          <FacebookShareButton url={`${location.origin}/match/${node.matchId}`} title={node.title}>
            <img src={FacebookIcon} alt="facebook share" />
          </FacebookShareButton>
          <LineShareButton url={`${location.origin}/match/${node.matchId}`} title={node.title}>
            <img src={LineIcon} alt="Line share" />
          </LineShareButton>
        </div>
      </div>
    </li>
  );

  const participantComponent = (node) => (
    <li key={node.id}>
      <Link to={`/artist/${contentPathHelper(node.id)}/`}>
        {(node.image_url || node.image) && (
          <div>
            <img src={node.image_url || node.image.url + '?w=400&q=80&fm=webp'} alt={`${node.name}${t('news.illustration')}`} />
          </div>
        )}
        <p>{node.name}</p>
      </Link>
    </li>
  );

  return (
    <Layout location={location}>
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
          {
            title: t('menu.news'),
            link: '/news',
          },
        ]}
        currentPage={microcmsEvent.title}
      />
      <Seo title={`${microcmsEvent.title}${t('news.seo.title')}`} description={postDescription} />
      <BackGround>
        <section className={Styles.eventId}>
          <div className={Styles.eventId__wrapper}>
            <div className={classNames(Styles.eventId__inner, CommonStyles.container)}>
              <div className={Styles.eventId__set}>
                {dayjs(microcmsEvent.publishedAt).format('YYYY/MM/DD')}
                <p className={Styles.eventId__event_category} style={{ backgroundColor: isNewsTypeColor('イベント') }}>
                  {t('news.event')}
                </p>
              </div>

              <h1>{microcmsEvent.title}</h1>

              <div className={Styles.eventId__thumbnail}>
                <img src={`${microcmsEvent.image.url}?w=1660&q=80`} />
              </div>

              <div
                className={Styles.eventId__description}
                dangerouslySetInnerHTML={{
                  __html: microcmsEvent.description,
                }}
              />

              <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList className={Styles.tab_list}>
                  <Tab className={classNames(Styles.tab_list__tab, tabIndex === 0 && Styles.tab_list__tab__active)}>
                    {t('news.outline')}
                  </Tab>
                  <Tab className={classNames(Styles.tab_list__tab, tabIndex === 1 && Styles.tab_list__tab__active, !microcmsMatch.edges.length && Styles.tab_list__not)}>
                    {t('news.matchInformation')}
                    <span className={Styles.tab_list__pop_icon}>{microcmsMatch.edges.length}</span>
                  </Tab>
                  <Tab className={classNames(Styles.tab_list__tab, tabIndex === 2 && Styles.tab_list__tab__active, !microcmsEvent.participant && Styles.tab_list__not)}>
                    {t('news.athletes')}
                    <span className={Styles.tab_list__pop_icon}>{microcmsEvent.participant.length}</span>
                  </Tab>
                  <Tab className={classNames(Styles.tab_list__tab, tabIndex === 3 && Styles.tab_list__tab__active, !microcmsEvent.rule && Styles.tab_list__not)}>
                    {t('news.rules')}
                  </Tab>
                  <Tab className={classNames(Styles.tab_list__tab, tabIndex === 4 && Styles.tab_list__tab__active)}>
                    {t('news.theme')}
                  </Tab>
                </TabList>

                <TabPanel className={classNames(Styles.tab_panel, tabIndex === 0 && Styles.tab_panel__active)}>
                  <section className={Styles.tab_panel__about}>
                    <div className={Styles.tab_panel__about__section}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: microcmsEvent.about.description,
                        }}
                      />
                    </div>

                    {microcmsEvent.about.prize && (
                      <div className={Styles.tab_panel__about__section}>
                        <h2>{t('news.prize')}</h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: microcmsEvent.about.prize,
                          }}
                        />
                      </div>
                    )}

                    {microcmsEvent.about.win_lose && (
                      <div className={Styles.tab_panel__about__section}>
                        <h2>{t('news.decidingTheWinner')}</h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: microcmsEvent.about.win_lose,
                          }}
                        />
                      </div>
                    )}

                    {microcmsEvent.about.judge_field && microcmsEvent.about.judge_field.length > 0 && (
                      <div className={Styles.tab_panel__about__section}>
                        <h3>{t('news.judge')}</h3>
                        <ul className={Styles.eventId__judge_list}>
                          {microcmsEvent.about.judge_field.map((member) => (
                            <li>
                              <img src={`${member.image.url}?w=300&h=300&fit=crop`} alt={member.name} />
                              <p>
                                <small>{member.title}</small>
                                <strong>{member.name}</strong>
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                    {microcmsEvent.about.ohter_field && microcmsEvent.about.ohter_field.map((other) => (
                      <div className={Styles.tab_panel__about__section}>
                        <h2>{other.title}</h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: other.content,
                          }}
                        />
                      </div>
                    ))}

                    {!microcmsEvent.about.description && !microcmsEvent.about.prize && !microcmsEvent.about.win_lose && microcmsEvent.about.judge_field.length === 0 && microcmsEvent.about.ohter_field.length === 0 && (
                      <div>
                        {t('news.noDescription')}
                      </div>
                    )}
                  </section>
                </TabPanel>

                <TabPanel className={classNames(Styles.tab_panel, tabIndex === 1 && Styles.tab_panel__active, !microcmsMatch.edges.length && Styles.tab_panel__not)}>{<ul className={Styles.result__ul}>{microcmsMatch.edges.map(({ node }) => matchComponent(node))}</ul>}</TabPanel>

                <TabPanel className={classNames(Styles.tab_panel, tabIndex === 2 && Styles.tab_panel__active, !microcmsEvent.participant && Styles.tab_panel__not)}>{microcmsEvent.participant && <ul className={Styles.artist_list}>{microcmsEvent.participant.map((node) => participantComponent(node))}</ul>}</TabPanel>

                <TabPanel className={classNames(Styles.tab_panel, tabIndex === 3 && Styles.tab_panel__active, !microcmsEvent.rule && Styles.tab_panel__not)}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: microcmsEvent.rule,
                    }}
                  />
                </TabPanel>

                <TabPanel className={classNames(Styles.tab_panel, tabIndex === 4 && Styles.tab_panel__active)}>
                  {microcmsEvent.concretes.length > 0 && microcmsEvent.abstracts.length > 0 && (
                    <div className={Styles.eventId__theme__wrap}>
                      <dl className={Styles.eventId__theme}>
                        <dt className={Styles.eventId__theme__title}>{t('match.group_a')}</dt>
                        {microcmsEvent.concretes.map(({ theme }) => (
                          <dd>{theme}</dd>
                        ))}
                      </dl>
                      <dl className={Styles.eventId__theme}>
                        <dt className={Styles.eventId__theme__title}>{t('match.group_b')}</dt>
                        {microcmsEvent.abstracts.map(({ theme }) => (
                          <dd>{theme}</dd>
                        ))}
                      </dl>
                    </div>
                  )}
                  {microcmsEvent.concretes.length === 0 && microcmsEvent.abstracts.length === 0 && (
                    <div>
                      {t('news.noTheme')}
                    </div>
                  )}

                  {/*<div className={Styles.eventId__roulette}>*/}
                  {/*  <h2>{t('news.themeRoulette')}</h2>*/}
                  {/*  <div>*/}
                  {/*    <p>{t('news.forPractice')}</p>*/}
                  {/*    <a href="/" target="_blank" rel="noopener noreferrer">*/}
                  {/*      <img src={BlankIcon} alt="_blank" /> {t('news.themeRoulette')}*/}
                  {/*    </a>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </TabPanel>
              </Tabs>

              <p className={Styles.eventId__btn}>
                <Link to={`/news/`}>{t('news.returnNews')}</Link>
              </p>
              <div className={Styles.eventId__share}>
                <TwitterShareButton url={location.href} title={microcmsEvent.title}>
                  <StaticImage src="../../images/twitter_share.png" alt="Twitter Share" />
                </TwitterShareButton>
                <FacebookShareButton url={location.href} title={microcmsEvent.title}>
                  <StaticImage src="../../images/facebook_share.png" alt="facebook Share" />
                </FacebookShareButton>
                <LineShareButton url={location.href} title={microcmsEvent.title}>
                  <StaticImage src="../../images/line_share.png" alt="Line Share" />
                </LineShareButton>
              </div>
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export const query = graphql`
  query ($eventId: String) {
    microcmsEvent(eventId: { eq: $eventId }) {
      eventId
      publishedAt
      date
      in_date
      out_date
      title
      image {
        url
      }
      description
      participant {
        id
        image_url
        image {
          url
        }
        name
        user_id
      }
      news_relation {
        id
        publishedAt
        revisedAt
        description
      }
      about {
        fieldId
        description
        prize
        win_lose
        ohter_field {
          fieldId
          title
          content
        }
        judge_field {
          fieldId
          image {
            url
          }
          title
          name
        }
      }
      rule
      concretes {
        fieldId
        theme
      }
      abstracts {
        fieldId
        theme
      }
      tournament {
        url
      }
    }

    allMicrocmsMatch(filter: { event: { id: { eq: $eventId } } }) {
      edges {
        node {
          matchId
          event {
            news_relation {
              id
            }
          }
          title
          date
          red {
            id
            name
            image_url
            image {
              url
            }
          }
          red_point
          blue {
            id
            name
            image_url
            image {
              url
            }
          }
          blue_point
          concrete
          abstract
          video_id
          publishedAt
          winner
        }
      }
    }
  }
`;

export default EventSinglePage;
